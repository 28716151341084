@import './donacionesForms';
@import './pagosModal';
.header-donaciones {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Nosotros/Donaciones/fondo.jpg');
    background-position: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-donaciones {
    display: flex;
    flex-direction: column;
}

.body-donaciones {
    margin-bottom: 80px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-donaciones {
    width: 45%;
    margin-bottom: -30px;
}

.body-donaciones .text {
    font-size: 20px;
    text-align: justify;
}

.donaciones-container {
    background: url('/assets/img/Nosotros/Donaciones/donaciones_fondo.png');
    background-repeat: repeat-y;
    background-size: 100%;
    margin-top: -110px;
    position: relative;
    height: 180px;
}

.donaciones-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.donaciones__buttonsdiv {
    background: $secondary;
    margin-top: -10px;
    padding: 0 20px;
    /* display: grid; */
    display: flex;
    align-items: center;
    gap: 20px;
    /* grid-template-columns: repeat(2, 1fr); */
    justify-content: center;
    flex-wrap: wrap;
}

.donaciones__buttonbox {
    align-self: center;
    border-radius: 44px;
    border: 2px solid $primary;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    overflow: hidden;
    width: 90%;
}

@media (min-width: $tablet) {
    .donaciones__buttonbox {
        width: 335px;
    }
}

@media (min-width: $desktopXL) {}

.donaciones__buttonbox img {
    max-width: 100%;
    display: block;
}

.btn-donacion img {
    width: 200px;
    height: 200px;
}

.donaciones__formdiv {
    background: $secondary;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.donaciones_login {
    background: #fff;
    padding: 10px;
    margin: 0 10px;
    border-radius: 10px;
}

.donaciones__subtitle_box {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 40px;
}

.donaciones__subtitle {
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 500;
}

@media (max-width: 374.98px) {
    .title-donaciones {
        height: 65px !important;
    }
    .donaciones-container {
        height: 35px;
    }
    .title-donaciones-sm {
        font-size: 55px !important;
    }
    .donaciones_login {
        padding: 10px;
        margin: 0 15px;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .header-donaciones,
    .body-donaciones {
        padding: 0 20px !important;
    }
    .title-donaciones {
        width: 100% !important;
    }
    .donaciones__subtitle_box {
        padding: 0 20px;
    }
    .donaciones__subtitle {
        font-size: 25px;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .donaciones__formdiv {
        padding: 10px;
    }
    .title-donaciones {
        width: 60%;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .donaciones-buttons {
        flex-direction: column !important;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}


/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1399.98px) {}