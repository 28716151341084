.main-slider {
    padding: 20px 40px 80px 40px;
}

.text-1 {
    margin-bottom: 0;
    font-size: 25px;
}

.alimento {
    height: 350px;
    padding-left: 25px;
}

.amorNutre {
    height: 90px;
}

.prox-btn {
    max-height: 50px;
}

.slide-2 {
    height: 450px;
    background: transparent;
    background-image: url('/assets/img/Landing/Banner02/dog.jpg');
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-position-y: 30%;
}

.slide2-img {
    /* max-height: 700px; */
    object-fit: cover;
}

.frase2 {
    max-width: 300px;
    padding-top: 40px;
    margin-left: 100px;
}

.slide-3 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 450px;
}

.dog3 {
    height: 370px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.frase3 {
    height: 150px;
}

.stars-div {
    background: transparent;
    background-image: url('/assets/img/Landing/Banner01/Estrellas.png');
    background-size: contain;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
}

.stars-div-2 {
    background: transparent;
    background-image: url('/assets/img/Landing/Banner03/fondo2.png'), url('/assets/img/Landing/Banner03/fondo1.png');
    background-size: contain;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    height: 350px;
    width: 350px;
}

@media (max-width: 374.98px) {
    .alimento {
        height: 220px !important;
        padding-top: 10px;
        padding-left: 0px !important;
    }
    .main-slider {
        padding: 0px !important;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .frase3 {
        height: 100px;
    }
    .frase2 {
        max-width: 200px;
        padding-top: 40px;
        margin-left: 100px;
    }
    .main-slider {
        padding: 30px;
        margin-bottom: 30px;
    }
    .amorNutre {
        height: 60px !important;
    }
    .text-1 {
        margin-bottom: 0;
        font-size: 15px;
    }
    .prox-btn {
        max-height: 30px;
    }
    .alimento {
        height: 280px;
        padding-left: 25px;
    }
    .slide-1text {
        padding-left: 20px;
        width: 50% !important;
    }
    .dog3 {
        height: 280px;
    }
    .frase3 {
        height: 80px !important;
        width: 80% !important;
        margin-left: 20px !important;
    }
    .stars-div-2 {
        height: 150px !important;
        width: 70% !important;
    }
    .slide-3 {
        padding-bottom: 70px;
    }
    .slide-1,
    .slide-2,
    .slide-3 {
        height: 400px !important;
        /* padding: 10px; */
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .frase3 {
        height: 120px;
    }
    .stars-div-2 {
        height: 200px;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .slide-1,
    .slide-2,
    .slide-3 {
        height: 525px;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}


/* X-Large devices (large desktops, less than 1400px) */

@media (min-width: 1399.98px) {
    .alimento {
        height: 450px;
    }
    .slide-1,
    .slide-2,
    .slide-3 {
        height: 550px;
    }
}