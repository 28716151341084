.expediente__register {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
    background-image: url('/assets/img/Equipo/Alianza/fondo.jpg');
    background-position-x: 80%;
    background-repeat: no-repeat;
    background-size: cover;
}

.expediente__subtitle {
    font-weight: bold;
    margin-top: 10px;
}

.expediente__logo {
    width: 75%;
}

.expediente__register__body {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 180px);
    padding: 20px;
    width: 35%;
}

.expediente__detalles {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
    padding: 20px;
}

.expediente__detalles__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.expediente__detalles .title {
    font-size: 35px;
}

.expediente__detalles .files__container {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-start;
    justify-content: space-around;
}

.expediente__detalles .btn-primary {
    background: $primary;
    border-color: $primary;
    box-shadow: none;
    color: #000;
    &:focus {
        background: $secondary !important;
        box-shadow: none;
    }
    &:active {
        background: $secondary !important;
        box-shadow: none;
    }
    &:hover {
        background: $secondary !important;
        box-shadow: none !important;
    }
}

.expediente__detalles .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #000;
    background-color: $secondary;
    border-color: $secondary;
    box-shadow: none !important;
}

.expediente__detalles .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

@media (max-width: 374.98px) {
    .expediente__detalles__header {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {
    .expediente__logo {
        width: 100%;
    }
}

@media (max-width: 991.98px) {
    .expediente__register__body {
        width: 100% !important;
        padding: 40px;
    }
}

@media (max-width: 1199.98px) {
    .expediente__register__body {
        width: 50%;
    }
}

@media (min-width: 1399.98px) {}