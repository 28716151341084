.mascotas__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: calc(100vh - 180px);
}

.modal_confirmacion {
    display: none;
    opacity: 1;
    z-index: 100;
    background-color: white;
    border-radius: 10px;
    position: fixed;
    width: 50%;
    height: auto;
    top: 27%;
    left: 25%;
    padding-top: 2%;
    padding-bottom: 2%;
    // margin: -25px 0 0 -25px;
}

.sombra_contenedor {
    box-shadow:
        inset 0 0 3em rgba(0, 0, 0, 0.1),
        0 0 0 1300px rgba(0, 0, 0, 0.5),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.mascotas__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mascotas__header p {
    font-family: "MidnightWorkers", "Aleo", "Open Sans", sans-serif;
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 0;
    /* text-shadow: -4px 1px $secondary;
    border-bottom: 6px solid $primary; */
    padding: 0 40px 0 0;
    transition: color 0.35s ease, text-shadow 0.35s ease;
}
.mascotas__header .active p {
    color: $primary;

    text-shadow: 1px 1px 2px black;
}

.botones_center_vertical {
    height: 65px;
    align-self: center;
}

.mascotas__header h3 {
    font-family: "MidnightWorkers", "Aleo", "Open Sans", sans-serif;
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 0;
}

.mascotas__header .btn-callejeritos {
    width: 120px;
    margin: 0;
}

.mascotas__body {
    margin-top: 10px;
    padding: 0 20px;
    min-height: calc(100vh - 280px);
}

.mascotas__body .card {
    border: 3px solid $secondary;
    border-radius: 15px;
}

.mascotas__body .card .card-img-top {
    margin: 10px;
    border: 3px solid $secondary;
    width: auto;
    border-radius: 15px;
}

.mascotas__body .card .card-title {
    font-weight: bold;
    font-size: 28px;
    text-align: center;
}

.mascotas__body .card .card__img {
    height: 53% !important;
}

.mascotas__body .card .card__detalles {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mascotas__body .card .card__detalles .btn-callejeritos {
    width: 45%;
}

.mascotas__body .card__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 500px;
}

.mascotas__footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.mascotas__footer p {
    font-weight: bold;
    margin-bottom: 0;
}

.mascotas__footer img {
    width: 180px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.mascotas_detalles_header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mascotas__detalles_title {
    font-family: "MidnightWorkers", "Aleo", "Open Sans", sans-serif;
    font-size: 55px;
    font-weight: bold;
    margin-bottom: 0;
}

.mascotas__detalles_subtitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

.btn-regresar {
    background: $primary;
    border-radius: 2px;
    color: $black;
    border-radius: 10px;
    font-weight: bold;
    font-style: normal;
    &:hover {
        background: $secondary;
    }
}

.text_desc:disabled {
    background: white;
}

.mascotas__container .table {
    border: 2px solid $primary !important;
    border-radius: 10px !important;
}

.mascotas__container .table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
    background-color: $primary;
}

.mascotas__container .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: white;
}

.filter_title {
    font-family: "MidnightWorkers", "Aleo", "Open Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0;
}

@media (max-width: 374.98px) {
    .mascotas__header {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .mascotas_detalles_header {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media (max-width: 575.98px) {
    .mascotas__header p {
        font-size: 40px;
    }
    .mascotas__header .btn-callejeritos {
        width: 100px !important;
        margin-top: 10px;
    }
}

@media (max-width: 767.98px) {
    .mascotas__container {
        padding: 0 20px !important;
    }
    .margin_file {
        margin-bottom: 25px;
    }
    .tamanio_img {
        height: 51vh !important;
    }
}

@media (max-width: 991.97px) {
    .tamanio_img {
        height: 51vh !important;
    }
}

@media (min-width: 991.98px) {
    .tamanio_img {
        height: 61vh !important;
    }
}

@media (max-width: 1199.98px) {
    .body-register {
        width: 50%;
    }
    .tamanio_img {
        height: 51vh !important;
    }
}
@media (min-width: 1199.99px) {
    .tamanio_img {
        height: 62vh !important;
    }
}

@media (min-width: 1399.98px) {
    .tamanio_img {
        height: 50vh !important;
    }
}
