/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
    bottom: 0;
    color: $black;
    font-size: 14px;
    background: $primary;
    width: 100%;
}

#footer .copyright {
    text-align: center;
    float: left;
    margin-left: 60px;
    margin-top: 20px;
}

#footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
    color: $black;
}

#footer .copyright .dog-footer {
    height: 50px;
    padding-bottom: 10px;
}

#footer .copyright .aviso-term {
    font-weight: bold;
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
}

#footer .copyright .aviso-term-div {
    display: flex;
    justify-content: space-between;
}

#footer .copyright .huella-img {
    max-height: 5px;
    margin-top: 7px;
}

#footer .copyright .cop {
    text-align: left;
    padding: 0 10px;
    color: white;
}

#footer .adoptme {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
}

#footer .adoptme .dog-adopt {
    height: 80px;
    margin-left: -50px;
}

#footer .adoptme .adopt-num {
    height: 30px;
    margin-top: 20px;
}

#footer .social {
    display: flex;
    justify-content: flex-end;
}

#footer .social .social-icon {
    height: 20px;
    margin-top: 25px;
    margin-right: 20px;
}

#footer .site_map {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    margin-top: 20px;
    text-align: start;
}

#footer .mapa_title {
    font-weight: bold;
    font-size: 18px;
}

.site_map ul {
    list-style:none;
    padding: 0 15px;
}

.map_item {
    font-weight: bold;
}

.map_active {
    border-bottom: 0.2rem solid white;
}

@media (min-width: 576px) {}

@media (max-width: 768px) {
    #footer .copyright,
    #footer .credits {
        float: none;
        text-align: center;
        padding: 2px 0;
    }

    #footer .site_map {
        flex-direction: column;
        margin-left: 0px;
    }

}

@media (min-width: 992px) {
    #footer .social .social-icon {
        margin-right: 60px;
    }
}

@media (min-width: 1200px) {}