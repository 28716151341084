@import './historia';
@import './causaSocial';
@import './donaciones';
@import './albergues';
.container-nosotros {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header-nosotros {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Nosotros/fondo.jpg');
    background-repeat: no-repeat;
    background-position-x: 70%;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.body-nosotros {
    margin-bottom: 20px;
    margin-top: 50px;
    padding: 0 40px;
}

.title-nosotros {
    margin-bottom: -30px;
    width: 50%;
}

.title-us {
    margin-bottom: -30px;
    width: 20%;
}

.body-nosotros .text {
    font-size: 20px;
    text-align: justify;
}

.nosotros-back {
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    /* padding: 10px 0; */
}

.nosotros-div {
    padding: 20px 60px 0 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nosotros-div .text {
    color: white;
    font-weight: bold;
    font-size: 28px;
    padding-bottom: 0;
    text-align: justify;
    width: 85%;
    font-family: "Aleo", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}

.title-img {
    height: 180px;
    margin-bottom: 20px;
}

.hecho-div {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
}

.hc-img {
    width: 160px;
    padding: 0 20px 0 40px;
}

.manifiesto {
    display: block;
}

.nosotros-textdiv {
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.nosotros-text {
    width: 80%;
}

@media (max-width: 374.98px) {
    .manifiesto-title-sm {
        font-size: 65px;
        margin-bottom: -40px;
    }
    .manifiesto_footer_right_text {
        margin-left: -100px;
        height: 140px;
    }
}

@media (max-width: 575.98px) {
    .body-nosotros {
        padding: 0 20px !important;
    }
}

@media (max-width: 767.98px) {
    .nosotros-textdiv {
        flex-direction: column;
    }
    .nosotros-text {
        width: 100%;
    }
    .hc-img {
        padding: 0;
    }
    .title-us {
        width: 40%;
    }
    .title-nosotros {
        width: 100%;
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}