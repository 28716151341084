.div-login {
    display: flex;
    flex-direction: column;
    /* min-height: calc(100vh - 180px) !important; */
}

.header__login {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Auth/Register/login_bg.jpg');
    background-position-y: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.body__login {
    margin-top: 45px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-height: calc(100vh - 180px); */
    padding: 0 40px 20px 40px;
}

.login_titleEN {
    width: 20%;
    margin-bottom: -85px !important;
    z-index: 999;
}

.login_titleES {
    width: 40%;
    margin-bottom: -25px;
    z-index: 999;
}

.body-login {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 180px);
    padding: 40px;
    width: 35%;
}

.cachorros_img {
    width: 18%;
    margin-left: 20px;
}

.login_btn {
    background: $secondary;
    border-radius: 2px;
    color: $black;
    border-radius: 10px;
    font-weight: bold;
    font-style: normal;
    margin-top: 5px;
    &:hover {
        background: $secondary;
    }
}

.login_link {
    text-align: right;
    color: dodgerblue;
    margin-top: 10px;
    &:hover {
        color: dodgerblue;
    }
}

.fade-2 {
    border-width: 0 0 4px;
    $color: $secondary;
    color: $color;
    border-image: linear-gradient( 90deg, rgba($secondary, 0), rgba($secondary, 1) 50%, rgba($secondary, 0) 100%) 0 0 100%;
    border-style: solid;
}

hr {
    border: 0;
    margin: 20px 0;
    max-width: 100%;
    background-position: 50%;
    box-sizing: border-box;
    width: 100%;
}

.div-recoverychange {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
    background-image: url('/assets/img/Equipo/Patrocinadores/fondo.jpg');
    background-position-x: 35%;
    background-repeat: no-repeat;
    background-size: cover;
}

.body-recoverychange {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 180px);
    padding: 40px;
    width: 35%;
}

.recoverychange_title {
    font-size: 28px;
    font-weight: bold;
    margin: 15px 0;
}

@media (max-width: 374.98px) {}

@media (max-width: 575.98px) {
    .body__login {
        padding: 20px;
    }
    .login_titleEN {
        width: 100% !important;
        margin-bottom: -25px !important;
    }
}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {
    .login_titleEN {
        width: 40%;
        margin-bottom: -45px;
    }
    .body__login,
    .login_titleES,
    .body-recoverychange {
        width: 100% !important;
    }
    .cachorros_img {
        display: none;
    }
    @media (max-width: 991.98px) {
        .register_titleES,
        .body-register {
            width: 100% !important;
        }
    }
}

@media (max-width: 1199.98px) {
    .body-login,
    .body-recoverychange {
        width: 50%;
    }
}

@media (min-width: 1399.98px) {}