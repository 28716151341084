/* Colors */


/*--------------------------------------------------------------
# PALLETE
1.- Pantone Black 6 C
    rgb(16, 24, 32) | 101820
2.-  Pantone 109 C (Amarillo 1)
    rgb(255, 209, 0) | ffd100
3.- Pantone 106 C (Amarillo 2)
    rgb(250, 227, 69) | fae345
4.- Pantone 663 C (Gris 1)
    rgb(229, 225, 230) | e5e1e6
5.- Pantone 420 C (Gris 2)
    rgb(200, 200, 200) | c8c8c8
--------------------------------------------------------------*/

$black: #101820;
$primary: #ffd100;
$secondary: #fae345;
$gray1: #e5e1e6;
$gray2: #c8c8c8;
$whatsapp: #25d366;
$white: #fff;
// Tamaño de Media Queries
$telefono: 374.98px;
$tablet: 768px;
$desktop: 1024px;
$desktopXL: 1199.98px;
$desktopXXL: 1399.98px;
