.header-patrocinadores {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Equipo/Patrocinadores/fondo.jpg');
    background-position-x: 20%;
    background-position-y: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-patrocinadores {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
}

.body-patrocinadores {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-patrocinadores {
    margin-bottom: -30px;
    width: 60%;
}

.title-patrocinadoresEN {
    margin-bottom: -60px;
    width: 40%;
}

.body-patrocinadores .text {
    font-size: 20px;
    text-align: justify;
}

.react-multi-carousel-item {
    align-items: center;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
}

.pat-item img {
    width: 100%;
    height: 145px;
    object-fit: contain;
    object-position: 50% 50%;
    padding-left: 20px;
    padding-right: 40px;
}

.react-multiple-carousel__arrow--right {
    right: 0% !important;
}

.react-multiple-carousel__arrow--left {
    left: 0% !important;
}

.arrow {
    position: absolute;
    background: transparent;
    border: 0;
    color: #000;
    top: 40%;
}

.right {
    right: 0;
}

.left {
    left: 0;
}

.carousel-control-prev-icon {
    background-image: url('/assets/img/Landing/Producto/arrow_left.png');
    filter: opacity(0.5);
    &:hover {
        filter: opacity(1);
    }
}

.carousel-control-next-icon {
    background-image: url('/assets/img/Landing/Producto/arrow_right.png');
    filter: opacity(0.5);
    &:hover {
        filter: opacity(1);
    }
}

.pat__img-arrow-right,
.pat__img-arrow-left {
    width: 40%;
    filter: opacity(0.5);
    &:hover {
        filter: opacity(1);
    }
}

.pat__img-arrow-right {
    margin-left: 0px;
}

.header-alianza {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Equipo/Alianza/fondo.jpg');
    background-position-x: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-alianza {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
}

.body-alianza {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-alianza {
    width: 80%;
    margin-bottom: -70px;
}

.title-alianzaEN {
    width: 80%;
    margin-bottom: -80px;
}

.body-alianza .text {
    font-size: 20px;
    text-align: justify;
}

.patrocinadores-text {
    font-weight: bold;
    font-size: 25px !important;
}

@media (max-width: 374.98px) {
    .pat-item img {
        padding-right: 15px;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {

    .body-patrocinadores,
    .body-alianza {
        padding: 0 20px !important;
    }

    .header-alianza {
        padding: 0 20px !important;
    }

    .header-patrocinadores {
        padding: 0 20px !important;
    }

    .title-patrocinadores {
        margin-bottom: -10px !important;
        width: 100% !important;
    }
    
    .title-patrocinadoresEN {
        margin-bottom: -30px !important;
        width: 90% !important;
    }

    .title-alianza {
        width: 100%;
        margin-bottom: -25px;
    }
    
    .title-alianzaEN {
        width: 100%;
        margin-bottom: -30px;
    }
}

@media (max-width: 767.98px) {
    .title-patrocinadores {
        margin-bottom: -20px;
        width: 80%;
    }
    
    .title-patrocinadoresEN {
        margin-bottom: -40px;
        width: 60%;
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}