.btn-callejeritos {
    background: $primary;
    border-radius: 2px;
    color: $black;
    border-radius: 10px;
    font-weight: bold;
    font-style: normal;
    &:hover {
        background: $secondary;
    }
}

.btn-detalles {
    background: $secondary;
    border-radius: 2px;
    color: $black;
    border-radius: 10px;
    font-weight: bold;
    font-style: normal;
    padding: 4px;
    &:hover {
        background: $primary;
        color: white;
    }
}

.btn-editar {
     background: white;
     border-radius: 2px;
     color: $black;
     border-radius: 10px;
     border-color: $primary;
     font-weight: bold;
     font-style: normal;

     &:hover {
        background: $secondary;
        color: white;
     }
}

.btn-callejeritos-accordion {
    color: $primary;
    background: transparent;
    border: none;
    border-radius: 0%;
    box-shadow: none !important;
    font-style: normal;
    font-weight: bold;
    outline: none !important;
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;

    &:hover {
        background: transparent;
        border: none;
        border-radius: 0%;
        color: $primary;
    }
    &:focus {
        background: transparent !important;
        border: none;
        box-shadow: none;
        color: $primary !important;
        outline: none;
        
    }
}

.fab-container {
    position: fixed;
    bottom: 25px;
    right: 15px;
    z-index: 999;
    cursor: pointer;
}
.fab-container.whatsapp {
    bottom: 115px;
    right: 25px;
    display: none;
    @media screen and (max-width: 768px){
        display: block;
    }
}


.fab-icon-holder {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: $primary;
   
}
.whatsapp .fab-icon-holder {
    background: $whatsapp;
    border-radius: 100%;
    display: grid;
    place-content: center;
    width: 60px;
    height: 60px;
}

.menu__icon {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    background: $primary;
    width: 80px;
    height: 80px;
}

.fab-icon-holder:hover {
    opacity: 0.8;
}

.fab-icon-holder i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 25px;
    color: #ffffff;
}

.fab {
    width: 80px;
    height: 80px;
    background: transparent;
}

.fab-options {
    list-style-type: none;
    margin: 0;
    position: absolute;
    bottom: 75px;
    right: 25px;
    opacity: 0;
    transition: all 0.3s ease;
    transform: scale(0);
    padding: 0;
    transform-origin: 85% bottom;
    background: $primary;
    border-radius: 10px !important;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.fab:hover+.fab-options,
.fab-options:hover {    
    transform: scale(1);
}

.fab-options li {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.fab-label {
    padding: 2px 5px;
    align-self: center;
    user-select: none;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}

.login-dw {
    padding: 20px;
    width: 300px !important;
    background: $primary;
}

.login-dw p {
    font-family: 'Aleo', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

.usuario__info {
    padding: 10px;
    text-align: center;
    background: $secondary;
    border-radius: 10px !important;
}

.menu_itm_w {
    background: #fff;
    align-items: center;
}

.menu_itm {
    background: $secondary;
    align-items: center;
}

.tamaño_menu {
    display: inline-block !important;
    justify-content: space-between;
    padding: 5px;
}

.menu_itm_icon {
    width: 20px;
}
@media (min-width: 668px) { 
    .whatsapp { display: none } 

    .instagram {
        background: linear-gradient(45DEG, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
    }
}