.logo {
    width: 8em;
}

#header {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#navbar {
    background: $primary;
    transition: all 0.5s;
    color: $black;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    color: $black;
    font-weight: bold;
    height: 80px;
    padding: 30px 10px 30px 10px;
    display: flex;
    flex-direction: column;
}

.navbar-light .navbar-nav .nav-link i {
    padding-bottom: 10px;
}

.navbar-light .navbar-nav .nav-link:hover i {
    border-bottom: 0.2rem solid white;
}

.navbar-light .navbar-nav .nav-link.active i,
.navbar-light .navbar-nav .show>.nav-link i {
    border-bottom: 0.2rem solid white;
}

.active2 i {
    padding-bottom: 13px;
    border-bottom: 0.2rem solid white;
}

.active2:hover {
    border-bottom: none;
}

.navbar-light .navbar-nav .dropdown-menu {
    background: $secondary;
    font-weight: bold;
    top: calc(100%);
    border-color: transparent;
    max-width: 300px;
    z-index: 99;
    text-align: center !important;
}

.navbar-light .navbar-nav .dropdown-item {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    white-space: initial;
    font-size: 16px;
    padding: 3px 15px;
}

.navbar-light .navbar-nav .dropdown-item small {
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
}

.navbar-light .navbar-nav .dropdown-item:hover,
.navbar-light .navbar-nav .dropdown-item:focus {
    background: $secondary !important;
}

.navbar-light .navbar-nav .dropdown-item:hover i {
    background: $secondary !important;
    border-bottom: 0.2rem solid white !important;
}

.navbar-light .dropdown-toggle::after {
    border: none;
}

a .active {
    padding-bottom: 13px;
    border-bottom: 0.2rem solid white !important;
}


/* .navbar-light .navbar-nav .dropdown-item:last-child i {
    border-bottom: none !important;
} */

.adopt-img {
    width: 5em;
    /* z-index: 999; */
    margin-bottom: -12px;
}

.hecho-img {
    margin-top: 5px;
    height: 50px;
    margin-left: 20px;
}

.images-div {
    align-items: center;
    display: flex;
    background: $primary;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    background: $primary;
}

.dropdown-item.active i,
.dropdown-item:active i {
    border-bottom: 0.2rem solid white;
}

.dropdown-item.active,
.dropdown-item:active {
    background: $secondary;
    color: $black;
}

.img-search {
    max-height: 20px;
    margin-right: 20px;
    margin-top: 52px;
}

.unavailable {
    cursor: pointer;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: $black !important;
}

.navbar-light .navbar-toggler {
    border-color: white;
    color: white;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('/assets/img/Header/toggle_icon.png');
}

.lang__boxright {
    display: block;
}

.lang__boxleft {
    display: none !important;
}

.btg-languaje-lg {
    width: 80px;
    cursor: pointer;
}

.btg-languaje-md {
    width: 50px;
    cursor: pointer;
}

.faqs_title {
    text-align: center !important;
    margin-top: -15px !important;
    font-size: 13px;
}

.user-icon {
    font-size: 21px;
}

.user-options {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    overflow-y: auto;
}

.user-options a {
    padding: 3px 0;
}

@media (max-width: 374.98px) {}

@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {
    .navbar-nav {
        height: 100%;
        max-height: calc(100vh - 60px) !important;
        overflow-y: scroll !important;
        padding-bottom: 30px;
    }
    .navbar-light .navbar-nav .nav-link,
    .navbar-light .navbar-nav .nav-link:hover {
        padding: 0;
        width: 100%;
        height: auto;
        padding: 5px 0;
    }
    .hecho-img,
    .adopt-img {
        display: none;
    }
    .lang__boxright {
        display: none !important;
    }
    .lang__boxleft {
        display: block !important;
    }
    .faqs_title {
        text-align: left !important;
        margin-top: 0px !important;
    }
}

@media (max-width: 1199.98px) {
    .hecho-img,
    .adopt-img {
        display: none;
    }
}

@media (max-width: 1399.98px) {}

@media (min-width: 1399.98px) {
    .faqs_title {
        text-align: left !important;
        margin-top: 0px !important;
    }
}