.esterilizacion-container {
    display: flex;
    flex-direction: column;
    margin: 0 40px;
    border-radius: 10px;
    padding: 20px 40px;
    background: white;
}

.esterilizacion-container .text {
    width: 100%;
}

@media (min-width: $desktopXL) {
    .esterilizacion-container .text {
        width: 70%;
    }
}

.costos-div {
    display: flex;
    flex-direction: row;
}

.form-donacion-div {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 30px 0;
    padding: 0;
}

.text {
    /* width: 100%; */
    font-size: 20px;
}


/* @media (min-width: $desktopXL) {
    .text {
        width: 70%;
    }
} */

.img-formato {
    max-height: 370px;
    transform: rotate(7deg);
}

.btn-descarga {
    text-align: left;
    background: $primary;
    color: $black;
    border-radius: 10px;
    font-weight: bold;
    font-style: normal;
    &:hover {
        background: $secondary;
    }
}

.btn-descarga-text {
    margin-bottom: 0;
    width: 70%;
}

.img-costos-personas {
    padding: 20px 20px 20px 60px;
}

.img-costos-empresas {
    padding: 20px 60px 20px 20px;
}

.body-donacionali-form {
    padding: 20px 40px;
    background: white;
    margin: 0 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-callejeritos {
    width: 25%;
}

.alert-callejeritos {
    background: $secondary;
    padding: 10px !important;
}

.pagolink__container {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 220px;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
}

.pagolink__container:hover {
    transform: scale(1.1);
}

.pagolink__img {
    width: 90px;
    height: 90px;
}

.pagolink__enlace {
    font-size: 22px;
    font-weight: bold;
}

.donaciones-formdiv {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px 60px 40px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
}

.pago_col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 50px;
}

.donaciones_checks {
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.donaciones_inpt {
    width: 50%;
}

.nota__div {
    max-width: 75%;
    margin: auto;
}

.subscriptions_options {
    /* background: pink; */
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
}

.form-check-label {
    font-size: 18px;
    font-weight: bold;
}

.form__title {
    font-size: 44px;
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
    margin-bottom: 0;
}


/* .donaciones-formdiv input[type=number] {
    width: 70%;
} */

@media (max-width: 374.98px) {
    .esterilizacion-container {
        padding: 0 10px;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .btn-descarga {
        width: 100% !important;
    }
    .btn-descarga-text {
        width: 100%;
    }
    .body-donacionali-form {
        padding: 10px;
        margin: 0 15px;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .costos-div {
        flex-direction: column;
    }
    .img-costos-personas {
        padding: 0;
    }
    .img-costos-empresas {
        padding: 0;
    }
    .form-donacion-div {
        flex-direction: column;
    }
    .donaciones_checks {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    /* .donaciones-formdiv input[type=number] {
        width: 100%;
    } */
    .donaciones-formdiv {
        padding: 0;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .btn-callejeritos {
        width: 50% !important;
    }
    .form-donacion-div {
        flex-direction: column;
    }
    .img-formato {
        margin-top: 30px;
    }
    .btn-descarga {
        margin: 10px 0 !important;
    }
    .donaciones_inpt {
        width: 100%;
    }
    .subscriptions_options {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
    .btn-descarga {
        margin: 0 10px;
    }
}


/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1399.98px) {}