.header-recursos {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Recursos/fondo.jpg');
    background-position-x: 35%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-recursos {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
}

.body-recursos {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.body-recursos-b {
    padding: 0 40px;
    margin-bottom: 20px;
}

.title-recursos {
    margin-bottom: -30px;
    width: 550px;
}

.body-recursos .text {
    font-size: 20px;
    text-align: justify;
}

.recusos-files {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
}

.recusos-files .btn-descarga {
    margin: 10px 20px !important;
    width: 90% !important;
}

.recusos-files .btn-descarga-text {
    width: 100%;
}

@media (max-width: 374.98px) {}

@media (max-width: 575.98px) {
    .title-recursos {
        width: auto !important;
        height: 70px;
    }

    .header-recursos,
    .body-recursos,
    .body-recursos-b {
        padding: 0 20px !important;
    }
}

@media (max-width: 767.98px) {
    .recusos-files {
        align-self: center;
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}