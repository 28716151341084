.container-form {
    padding-top: 30px;
    padding-bottom: 40px;
    max-width: 100vw !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.form-div {
    padding: 0 60px;
    min-width: 65%;
}

.form-div .contacto-title {
    font-size: 38px;
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
}

textarea {
    resize: none !important;
}

.contacto-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 65px;
}

.contacto-div .title {
    font-weight: bold;
}

.contacto-div .text {
    max-width: 85%;
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
}

@media (max-width: 374.98px) {
    .form-div {
        padding: 0 20px;
    }
    .contacto-div {
        padding: 0 20px;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .form-div {
        padding: 20px !important;
    }
    .contacto-div {
        padding: 0 20px !important;
    }
    .div-doglover {
        flex-direction: column;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
    .container-form {
        flex-direction: column;
        align-items: center;
    }
    .contacto-div {
        margin-top: 25px;
        padding: 0 60px;
        justify-content: center;
    }
    .contacto-div .text {
        max-width: 100%;
    }
}


/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1399.98px) {}