.container {
    padding: 0;
}

.container p {
    color: $black;
    text-align: center;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 170px;
    border: 3px dashed $primary;
}

.drop-message {
    text-align: center;
    color: $black;
    font-size: 20px;
}

.file-display-container {
    margin-top: 15px;
    /*  position: fixed;
    width: 805px; */
}

.file-status-bar {
    width: 100%;
    vertical-align: top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}

.file-status-bar>div {
    overflow: hidden;
    cursor: pointer;
}

.file-type {
    display: inline-block !important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: $black;
    background: $primary;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: $black;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 50px;
    height: 50px;
    background: url('/assets/img/generic.png') no-repeat center center;
    background-size: 100%;
    position: absolute;
}

.file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693D;
    margin-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}

.modal {
    z-index: 99999;
    display: none;
    overflow: hidden;
}

.modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .90);
    position: absolute;
    top: 0;
    left: 0;
}

.modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.close {
    position: absolute;
    top: 25px;
    right: 85px;
    color: red;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 99999;
    cursor: pointer;
    &:hover {
        color: red;
        transform: scale(1.3);
    }
}

.video-shown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.file-input {
    display: none;
}

.img-gallery {
    cursor: pointer;
}