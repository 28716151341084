

.faqs__container {
    margin-top: 30px;
    min-height: calc(100vh - 180px);
}

.faqs__titlebox {
    background: black;
    width: 50%;
    padding: 10px 30px 0 40px;
}

.faqs__container .title-gray {
    text-transform: uppercase;
    color: $gray2;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: normal;
    font-size: 24px;
    text-align: left;
}

@media (max-width: 575.98px) {
    .faqs__titlebox {
        padding: 10px 10px 0 10px;
    }
}

@media (max-width: 767.98px) {
    .faqs__titlebox {
        width: 100% !important;
    }
}

@media (max-width: 1199.98px) {
    .faqs__titlebox {
        width: 80%;
    }
}