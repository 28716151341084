.container-prod {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.container-imp {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.img-alimento {
    max-height: 400px;
}

.img-arrow {
    max-height: 15px;
}

.desc-div {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: center;
}

.desc-div .title {
    font-weight: bold;
    font-size: 30px;
}

.reg {
    font-size: 17px;
}

.btn-callejeritos {
    width: 18%;
    margin-top: 20px;
}

.desc-div .text {
    margin-bottom: 0;
}

.carousel-caption {
    right: 2%;
    bottom: 1.2rem;
}

.imp_title {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
    color: white;
}

#croq {
    width: 80%;
    padding: 60px;
}

#productoCarousel {
    width: 400px;
}

.mancha-div {
    background: transparent;
    background-image: url('/assets/img/Landing/Producto/mancha.png');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.leer-mas {
    cursor: pointer;
    margin-top: 5px;
    /* color: white; */
}

.imp-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.imp-div .title-gray {
    text-transform: uppercase;
    color: $gray2;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 20px;
    letter-spacing: 2px;
}

.imp-div .title {
    font-size: 25px;
    font-weight: bold;
    max-width: 50%;
    margin-bottom: 0;
}

.imp-div .text {
    margin-bottom: 0;
}

.div-doglover {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

.doglover {
    width: 40%;
    margin: 0 auto;
}

#productoCarousel .carousel-control-prev-icon {
    background-image: url('/assets/img/Landing/Producto/arrow_left.png');
}

#productoCarousel .carousel-control-next-icon {
    background-image: url('/assets/img/Landing/Producto/arrow_right.png');
}

.carousel-caption {
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 30px;
}

.impacto-textos {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: start;
}

@media (max-width: 374.98px) {
    #productoCarousel {
        width: 320px;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .desc-div {
        padding: 0 20px;
        margin-top: 0px;
    }
    .img-alimento {
        max-height: 300px;
    }
    .btn-callejeritos {
        width: 45%;
        margin-top: 20px;
    }
    .container-prod {
        padding-top: 30px;
    }
    .imp_title {
        font-weight: bold;
        font-size: 16px;
    }
    .mancha-div {
        height: 300px;
    }
    .container-prod {
        justify-content: flex-start !important;
    }
    #croq {
        width: 100% !important;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .container-prod {
        flex-direction: column;
        align-items: center;
    }
    .desc-div {
        max-width: 90%;
        margin-top: 20px;
    }
    .imp_title {
        font-weight: bold;
        font-size: 20px;
    }
    .imp-div {
        padding: 0;
    }
    .imp-div .title {
        max-width: 100%;
    }
    .doglover {
        width: 60%;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .container-imp {
        flex-direction: column;
        align-items: center;
    }
    .container-imp {
        padding-top: 40px;
    }
    .imp_title {
        font-weight: bold;
        font-size: 20px;
    }
    .imp-div {
        max-width: 80%;
    }
    .imp-div .title {
        max-width: 100%;
    }
    #croq {
        width: 70%;
        margin-top: 0;
        padding: 0 !important;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}


/* X-Large devices (large desktops, less than 1400px) */

@media (min-width: 1399.98px) {
    #croq {
        width: 40%;
        padding: 30px 60px 0 60px;
    }
}