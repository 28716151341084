.pagosmodal__body {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.pago_type_img {
    width: 350px;
    cursor: pointer;
    transition: transform .3s ease;
    &:hover {
        transform: scale(1.2);
    }
}

.loading_icon {
    color: $primary;
}

.confirm-donation-body {
    align-items: center;
    display: flex;
    height: calc(100vh - 180px);
    justify-content: center;
    padding: 40px;
}

.confirm-donation-content h1 {
    font-weight: bold;
}

.confirm-donation-content p {
    font-size: 20px;
    margin-bottom: 0;
}

.confirm-donation-content {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.oxxo_modal {
    width: 90%;
    max-width: none !important;
}

.pagoslinks__modal__body {
    width: 70%;
    max-width: none !important;
}

.pagoslinks__modal__header {
    align-items: center;
}

.pagoslinks__modal__col {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}


/* OXXO PAY */

.opps {
    /* width: 496px; */
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 25px;
    overflow: hidden;
    border: 1px solid #b0afb5;
    margin: 0px auto;
    font-family: "Open Sans", sans-serif;
    color: #4f5365;
}

.opps-reminder {
    position: relative;
    top: -1px;
    padding: 9px 0 10px;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    background: #000000;
}

.opps-info {
    margin-top: 26px;
    /* position: relative; */
    display: flex;
    flex-direction: row;
    align-self: center;
}

.opps-info:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.opps-brand {
    width: 45%;
}

.opps-brand img {
    max-width: 150px;
    margin-top: 2px;
    padding-right: 5px;
}

.opps-ammount {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.opps-ammount h2 {
    font-size: 36px;
    color: #000000;
    line-height: 24px;
    margin-bottom: 15px;
}

.opps-ammount h2 sup {
    font-size: 16px;
    position: relative;
    top: -2px;
}

.opps-ammount p {
    font-size: 10px;
    line-height: 14px;
}

.opps-reference {
    margin-top: 14px;
}

.opps h1 {
    font-size: 27px;
    color: #000000;
    text-align: center;
    margin-top: -1px;
    padding: 6px 0 7px;
    border: 1px solid #b0afb5;
    border-radius: 4px;
    background: #f8f9fa;
}

.opps h3 {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.opps-instructions {
    /* margin: 32px -45px 0; */
    /* padding: 32px 45px 45px; */
    padding: 20px;
    border-top: 1px solid #b0afb5;
    background: #f8f9fa;
    width: 80%;
}

.opps ol {
    /* margin: 17px 0 0 16px; */
    padding-inline-start: 20px;
}

.opps li+li {
    margin-top: 10px;
    color: #000000;
}

.opps a {
    color: #1155cc;
}

.opps-footnote {
    margin-top: 22px;
    padding: 22px 20 24px;
    color: #108f30;
    text-align: center;
    border: 1px solid #108f30;
    border-radius: 4px;
    background: #ffffff;
}


/* FIN OXXO PAY */

@media (max-width: 374.98px) {}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .oxxo_modal,
    .pagoslinks__modal__body {
        width: 95%;
    }
    .opps {
        padding: 10px;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .opps {
        flex-direction: column;
    }
    .opps-info {
        align-items: center;
    }
    .opps-ammount {
        align-items: center;
    }
    .opps-instructions {
        width: 100%;
    }
    .pagoslinks__modal__header {
        flex-direction: column;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .opps-info {
        flex-direction: column;
    }
    .opps-ammount {
        margin-top: 10px;
    }
    .pagoslinks__modal__body {
        width: 80%;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
    .pagoslinks__modal__body {
        width: 90%;
    }
}


/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1399.98px) {}