.dog {
    height: 420px;
    padding: 20px 80px;
}

.compromiso-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -50px;
    background: $primary;
}

.div-comp-dog {
    width: 100%;
    display: flex !important;
    align-items: flex-end !important;
}

.compromiso {
    width: 45%;
}

.compromiso h1 {
    margin-bottom: 2px;
    font-size: 46px;
}

.compromiso .title {
    margin-bottom: 0;
    font-weight: bolder;
    font-size: 19px;
}

.compromiso .text {
    margin-bottom: 0;
    font-size: 19px;
}

.div-comp {
    background: transparent;
}

.impacto-container {
    background: $primary;
    display: flex;
    justify-content: space-around;
}

.imp-img {
    margin-top: 50px;
    max-height: 200px;
    margin-bottom: 50px;
}

.compromiso-container {
    background: url('/assets/img/Landing/Compromiso/fondo.png');
    background-repeat: repeat-y;
    background-size: 100%;
    margin-top: -130px;
    position: relative;
    height: 150px;
    z-index: -1;
}

.compromiso h1 {
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
}

.home_login {
    background: $primary;
    padding: 40px 0 90px 40px;
    margin: 0;
}

.home_cat {
    width: 220px;
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .div-comp {
        background: $primary;
        width: 100%;
    }
    .dog {
        height: 320px !important;
        background: $primary;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
    }
    .compromiso {
        margin-left: 0;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .compromiso-div {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 50px;
    }
    .compromiso {
        margin-top: 0;
        width: 100%;
    }
    .div-comp {
        background: $primary;
        /* ffd100 */
    }
    .impacto-container {
        flex-direction: column;
        align-items: center;
    }
    .imp-img {
        max-width: 280px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .imp-img {
        max-height: 150px;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {}


/* X-Large devices (large desktops, less than 1400px) */

@media (min-width: 1399.98px) {
    .compromiso-container {
        margin-top: -170px;
        height: 250px;
    }
    .compromiso-div {
        margin-top: -10px;
    }
}