$size: 35px;
@function position($size){
    @return -1 * $size / 2;
}
.portal {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    background-color: #00000080;
    z-index: 1020;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    .portal-content{
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba($black, 0.1);
        padding: 20px;
        position: relative;
        width: 100%;
        
        max-width: 500px;
        margin: 0 auto;
        z-index: 1000;
        position: relative;
        .portal-close{

            border-radius: 100%;
            height: $size;
            width: $size;
            filter: drop-shadow(0 0 0.15rem #00000080);
            background-color: #fff;
            position: absolute;
            right:  position($size);
            top: position($size);
        }
    }
}
body {
    position: relative;
    // overflow: hidden;
}
