.div-albergues {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
}

.header-albergues {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Nosotros/Albergues/fondo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 30%;
    background-position-y: 30%;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.body-albergues {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-alberguesES {
    margin-bottom: -100px;
    width: 60%;
}

.title-alberguesEN {
    margin-bottom: -30px;
    width: 50%;
}

.body-albergues .text {
    font-size: 20px;
    text-align: justify;
}

@media (max-width: 374.98px) {
    .title-alberguesES {
        margin-bottom: -50px !important;
    }
}

@media (max-width: 575.98px) {
    
    .header-albergues,
    .body-albergues {
        padding: 0 20px !important;
    }

    .title-alberguesES {
        margin-bottom: -70px;
        width: 100% !important;
    }
    
    .title-alberguesEN {
        margin-bottom: -30px !important;
        width: 100% !important;
    }
}

@media (max-width: 767.98px) {
    .title-alberguesES {
        width: 80%;
        margin-bottom: -70px;
    }
    
    .title-alberguesEN {
        width: 70%;
    }
    
}