@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
@font-face {
    font-family: 'MidnightWorkers';
    src: url(../../fonts/midnight/Midnight_Workers.ttf);
}

* {
    font-family: 'Aleo', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

body {
    font-family: 'Aleo', "Open Sans", sans-serif;
    color: $black;
}

a {
    color: $black;
    text-decoration: none;
    &:hover {
        color: $black;
    }
}

.title-gray {
    text-transform: uppercase;
    color: $gray2;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 5px;
    font-size: 24px;
}

i {
    font-style: normal;
}

.form-control {
    border: 2px solid #ffd100 !important;
    border-radius: 1rem !important;
}

.form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

button {
    background: transparent;
    border: none;
}

.callejeritos_accordion_header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.btn_descarga_content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 374.98px) {
    .title-gray {
        font-size: 20px !important;
    }
}


/* LOADER */

.loader_btn {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3.75px solid;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  @keyframes animloader {
    0% {
      border-color: rgb(178, 178, 178) rgba(178, 178, 178, 0) rgba(178, 178, 178, 0) rgba(178, 178, 178, 0);
    }
    33% {
      border-color: rgb(178, 178, 178) rgb(178, 178, 178) rgba(178, 178, 178, 0) rgba(178, 178, 178, 0);
    }
    66% {
      border-color: rgb(178, 178, 178) rgb(178, 178, 178) rgb(178, 178, 178) rgba(178, 178, 178, 0);
    }
    100% {
      border-color: rgb(178, 178, 178) rgb(178, 178, 178) rgb(178, 178, 178) rgb(178, 178, 178);
    }
  } 

/* Absolute Center Spinner */

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


/* Transparent Overlay */

.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.35), rgba(0, 0, 0, 0.35));
    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.35), rgba(0, 0, 0, 0.35));
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.chk_custom_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 10px;
}


/* Hide the browser's default checkbox */

.chk_custom_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid $primary;
}


/* On mouse-over, add a grey background color */

.chk_custom_container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.chk_custom_container input:checked~.checkmark {
    background-color: $primary;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.chk_custom_container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.chk_custom_container .checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.justify-content-evenly{
    justify-content: space-evenly;
}