.header-unete {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Auth/Register/login_bg.jpg');
    background-position-y: 90%;
    background-position-x: 91%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    height: 550px;
    padding: 0 40px;
}

.div-unete {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.body-unete {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.body-unete .text {
    font-size: 18px;
    text-align: justify;
}

.title-unete {
    width: 60%;
    margin-bottom: -45px;
}

.header-informate {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Contacto/Informate/fondo.jpg');
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-informate {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.body-informate {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-informateES {
    width: 40%;
    margin-bottom: -20px;
}

.title-informateEN {
    width: 60%;
    margin-bottom: -50px;
}

.body-informate .text {
    font-size: 18px;
    text-align: justify;
}

.btn-callejeritos {
    width: 25%;
}

.header-programa {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Auth/Register/fondo_register.jpg');
    background-position-y: 25%;
    background-position-x: 55%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.div-programa {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.body-programa {
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 0 40px;
}

.title-programaES {
    width: 60%;
    display: block;
    margin-bottom: -30px;
}

.title-programaEN {
    width: 70%;
    display: block;
    margin-bottom: -55px;
}

.body-programa .title {
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 18px;
}

.body-programa .text {
    font-size: 18px;
    text-align: justify;
}

.div-boletin {
    min-height: calc(100vh - 160px) !important;
    padding: 0 40px;
    display: flex;
    align-items: center;
}

.boletin-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.boletin_imgbox {
    width: 25%;
    padding: 10px;
}

.boletin_formbox {
    width: 40%;
}

.boletin_formbox h1 {
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
}

.aviso__container,
.terminos__container {
    margin-top: 30px;
    min-height: calc(100vh - 180px);
}

.aviso__titlebox,
.terminos__titlebox {
    background: black;
    width: 50%;
    padding: 10px 30px 0 40px;
}

.aviso__title,
.terminos__title {
    color: white;
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
    text-transform: uppercase;
}

.aviso__content,
.terminos__content {
    padding: 20px 70px;
    margin-bottom: 40px;
}

.aviso__content p,
.terminos__content p {
    text-align: justify !important;
}

.text-link {
    color: #0645AD;
}

.download_section {
    width: 50%;
    margin: 0 auto;
}

.presentation_section {
    width: 25%;
    margin: 0 auto;
}

@media (max-width: 374.98px) {
    .aviso__title {
        font-size: 35px !important;
    }
    .terminos__title {
        font-size: 28px !important;
    }
    .title-programaEN {
        margin-bottom: -25px !important;
    }
}


/* X-Small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .btn-callejeritos {
        width: 75% !important;
    }
    .aviso__titlebox,
    .terminos__titlebox {
        padding: 10px 10px 0 10px;
    }
    .terminos__title {
        font-size: 35px;
    }
    .header-programa,
    .body-programa,
    .header-informate,
    .body-informate,
    .header-unete,
    .body-unete {
        padding: 0 20px !important;
    }
    .title-unete {
        width: 100% !important;
        margin-bottom: -20px !important;
    }
    .title-informateES {
        width: 80% !important;
    }
    .title-informateEN {
        width: 100% !important;
        margin-bottom: -25px !important;
    }
    .title-programaEN {
        width: 100% !important;
        margin-bottom: -35px;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .btn-callejeritos {
        width: 55%;
    }
    .boletin-body {
        flex-direction: column;
    }
    .boletin_imgbox {
        width: 50%;
    }
    .boletin_formbox {
        width: 100%;
    }
    .aviso__titlebox,
    .terminos__titlebox {
        width: 100% !important;
    }
    .title-unete {
        width: 80%;
        margin-bottom: -35px;
    }
    .title-informateES {
        width: 60%;
    }
    .title-informateEN {
        width: 80%;
        margin-bottom: -35px;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .btn-callejeritos {
        width: 50%;
    }
    .aviso__titlebox {
        width: 70%;
    }
    .aviso__content,
    .terminos__content {
        padding: 10px;
    }
    .title-programaES {
        width: 100%;
        margin-bottom: -15px;
    }
    .title-programaEN {
        width: 90%;
    }
    .download_section,
    .presentation_section {
        width: 100%;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
    .btn-callejeritos {
        width: 35%;
    }
    .terminos__titlebox {
        width: 80%;
    }
}


/* X-Large devices (large desktops, less than 1400px) */

@media (max-width: 1399.98px) {}