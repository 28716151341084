.misdonativos_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
    padding: 0 40px;
}

.misdonativos_header {
    display: flex;
    /* border-bottom: 1px solid $gray1; */
    justify-content: space-between;
    padding: 20px 0 5px;
}

.misdonativos_header p {
    font-family: 'MidnightWorkers', 'Aleo', "Open Sans", sans-serif;
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0 40px 0 0;
}

.misdonativos_body {
    padding: 20px 0 5px;
}

.misdonativos_body .table {
    border: 2px solid $primary !important;
    border-radius: 10px !important;
}

.misdonativos_body .table td {
    vertical-align: middle;
    white-space: nowrap;
}

@media (max-width: 374.98px) {}

@media (max-width: 575.98px) {
    .misdonativos_container {
        padding: 0 15px;
    }
    .misdonativos_header p {
        font-size: 40px;
    }
}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (min-width: 1399.98px) {}