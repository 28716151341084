.contenedor_popup {
    width: 80%;
    min-width: 350px;
    margin-left: 8%;
    margin-right: 10%;
    /* padding: 10%; */
    box-shadow:inset 0 -3em 3em rgba(0,0,0,0.1),0 0  0 850px rgba(0,0,0,0.2),0.3em 0.3em 1em rgba(0,0,0,0.9);
    z-index:1;
    position:fixed;
    border-radius: 10px;
    background-color: white;
  }

  .mascotas__bodyy {
    margin-top: 10%;
    padding: 20px 20px;
    min-height: calc(50vh - 280px);
}

.contenedor_popup2 {
  width: 40%;
  min-width: 350px;
  align-self: center;
  /* padding: 10%; */
  box-shadow:inset 0 -3em 3em rgba(0,0,0,0.1),0 0  0 850px rgba(0,0,0,0.2),0.3em 0.3em 1em rgba(0,0,0,0.9);
  z-index:1;
  position:fixed;
  border-radius: 10px;
  background-color: white;
}

.eliminar_body{
  display: flex;
  justify-content: center;
}

/* .overflow {
  overflow-x: hidden
} */