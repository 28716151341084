.div-alimento {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px) !important;
}

.header-alimento {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Productos/Alimento/fondo.jpg');
    background-position-x: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.body-alimento {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-alimento {
    width: 40%;
    margin-bottom: -30px;
}

.title-alimentoEN {
    width: 20%;
    margin-bottom: -30px;
}

.body-juguetes .title,
.body-alimento .title {
    margin-top: 20px;
    font-size: 83px;
    text-transform: uppercase;
}

.body-alimento .text {
    font-size: 20px;
    text-align: justify;
    letter-spacing: 3px;
}

.alimento-galeria {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
}

.alimento-galeria img {
    height: 420px;
}

.alimento-izq {
    max-height: 450px;
}

.div-juguetes {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header-juguetes {
    align-items: flex-end;
    background: transparent;
    background-image: url('/assets/img/Productos/Juguetes/fondo.jpg');
    background-position-x: 40%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 550px;
    justify-content: flex-start;
    padding: 0 40px;
}

.body-juguetes {
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 0 40px;
}

.title-juguetes {
    margin-bottom: -80px;
    width: 40%;
}

.title-juguetesEN {
    margin-bottom: -60px;
    width: 20%;
}

.body-juguetes .title,
.body-alimento .title {
    font-family: 'MidnightWorkers', 'Aleo', sans-serif;
}

@media (max-width: 374.98px) {

    .title-alimentoEN {
        height: 120px !important;
    }

    .body-juguetes .title,
    .body-alimento .title {
        font-size: 45px !important;
    }
}


@media (max-width: 575.98px) {
    .body-juguetes .title,
    .body-alimento .title {
        margin-top: 20px;
        font-size: 50px;
    }

    .title-alimento {
        width: 100% !important;
    }
    
    .title-alimentoEN {
        width: 80% !important;
    }

    .body-alimento,
    .header-alimento,
    .body-juguetes,
    .header-juguetes {
        padding: 0 20px !important;
    }

    .title-juguetes {
        width: 100% !important;
        margin-bottom: -60px;
    }

    .title-juguetesEN {
        width: 50% !important;
        margin-bottom: -40px;
    }
}

@media (max-width: 767.98px) {
    .alimento-galeria {
        flex-direction: column;
        align-items: center;
    }
    .alimento-galeria img {
        max-width: 50% !important;
    }

    .title-alimento {
        width: 70%;
    }
    
    .title-alimentoEN {
        width: 50%;
    }

    .title-juguetes {
        width: 70%;
        margin-bottom: -50px;
    }

    .title-juguetesEN {
        width: 40%;
        margin-bottom: -40px;
    }
}

@media (max-width: 991.98px) {
    .alimento-galeria img {
        height: 250px;
    }

    .title-alimento {
        width: 50%;
    }
    
    .title-alimentoEN {
        width: 30%;
    }

    .title-juguetes {
        width: 50%;
        margin-bottom: -60px;
    }

    .title-juguetesEN {
        width: 25%;
        margin-bottom: -40px;
    }
}

@media (max-width: 1199.98px) {
    .alimento-galeria img {
        height: 320px;
    }
}